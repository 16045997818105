<template>
  <div>
    <div class="spoiler-title">
      <slot name="title"></slot>
      <img @click="toggleSpoiler" class="icon-expand" :class="{ 'icon-expand-opened': opened }" src="@/assets/images/expand.svg" />
    </div>
    <transition-group name="dropdown" tag="section" class="dropdown" :style="dropdown">
      <div v-show="opened" ref="content" key="key">
        <slot></slot>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    initiallyOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      dropdown: {
        height: 0,
      },
    };
  },
  methods: {
    toggleSpoiler() {
      this.opened = !this.opened;
      this.$emit('update:opened', this.opened);
    },
    updateHeight() {
      if (!this.opened) {
        this.dropdown.height = 0;
      } else {
        this.dropdown.height = `${this.$refs.content.clientHeight}px`;
      }
    },
  },
  watch: {
    initiallyOpened(newVal) {
      this.opened = newVal;
    },
    opened(newVal) {
      this.opened = newVal;
      this.$nextTick(this.updateHeight);
    },
  },
  mounted() {
    this.opened = this.initiallyOpened;
    this.$emit('update:opened', this.opened);
  },
  updated() {
    this.updateHeight();
  },
};
</script>

<style scoped>
.spoiler-title {
  position: relative;
}
.dropdown {
  position: relative;
  height: 0;
  /* overflow: hidden; */
  transition: height 350ms;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown-leave,
.dropdown-enter-to {
  opacity: 1;
}

.dropdown-enter-active,
.dropdown-leave-active {
  position: absolute;
  width: 100%;
  transition: opacity 200ms ease-in-out;
}

.dropdown-enter-active {
  transition-delay: 100ms;
}

.icon-expand {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 8px);
  right: 0px;
  transition: all 200ms ease-in-out;
}

.icon-expand-opened {
  transform: rotate(180deg);
}
</style>
