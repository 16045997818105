<template>
  <v-dialog v-model="isDialogVisible" max-width="1280px">
    <v-card class="pa-8 pa-sm-16">
      <v-btn class="button-close ma-4" icon @click="onClose()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 class="text-center">
        {{ editMode ? ($store.getters['auth/isAllowed']('change_professiogram') ? 'Редактирование' : 'Просмотр') : 'Создание'}}
        профессиограммы
      </h2>
      <form @submit.prevent="saveProf" class="mt-6">
        <div class="container">
          <!-- <h3 class="mb-2">Введите название профессии</h3> -->
        <div class="row">
          <div class="col-6">
            <v-text-field label="Название профессии *" outlined dense
                v-model="editedItem.name" @input="$v.editedItem.name.$model = $event, onInput('name')"
                :error-messages="nameErrors"
            />
          </div>
          <div class="col-6">
            <v-select  v-model="editedItem.owner" :items="customers" label="Владелец"
              :item-text="(item) => (item.first_name || item.last_name ? item.last_name + ' ' + item.first_name : 'имя не задано') +
                                ' (' + (item.user_role === 'corpclient' ? 'Клиент' : (item.user_role === 'partner' ? 'Партнер' : '')) + ', ' + item.email + ')'"
              item-value="id" outlined dense  :error-messages="ownerErrors"
            />
          </div>
          </div>
          <v-divider class="my-2"></v-divider>

          <h3 class="my-2">Выберите раздел</h3>
          <div class="d-flex flex-wrap">
            <v-btn v-if="$store.getters['auth/isAllowed']('add_chapter')"
              class="mr-5 mb-2 px-3 py-5" _color="primary" outlined @click="showDlgCreateChapter=true"
            >
              Новый раздел
            </v-btn>
            <div v-for="c in chapters" :key="c.id" class="chapter-checkbox mr-5"
              :class="{ 'chapter-checkbox-active': editedItem.chapters.includes(c.id)}"
            >
              <v-checkbox v-model="editedItem.chapters" @change="$v.$touch()" :label="c.name" :value="c.id"
                dense hide-details class="mt-0" />
            </div>
          </div>
          <div class="error--text error-msg">{{chapterErrors}} </div>

          <v-divider class="my-2"></v-divider>
          <h3 class="my-2">Психологический тест</h3>
          <div class="my-2 _scale-wrapper">
            <!-- <v-select  item-value="id" v-model="calcType" :items="calcTypes" label="Расчет соответствия" outlined dense /> -->
            <v-expansion-panels _v-model="opened">

              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-5">Настройки расчета по пикам</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table :headers="headersPsycho" :items="editedItem.scales" :items-per-page="-1" hide-default-footer>
                    <template v-slot:item.peak="{ item }">
                      <v-simple-checkbox :ripple="false" :value="Boolean(item.peak)" @input="item.peak=$event" color="primary" />
                    </template>
                    <template v-slot:item.down="{ item }">
                      <v-simple-checkbox :ripple="false" :value="Boolean(item.down)" @input="item.down=$event" color="primary" :disabled="!item.peak" />
                    </template>
                    <template v-slot:item.limit="{ item }">
                      <v-simple-checkbox :ripple="false" :value="Boolean(item.limit)" @input="item.limit=$event" color="primary" :disabled="!item.peak" />
                    </template>
                    <template v-slot:item.rank="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.rank" :disabled="!item.peak" :error-messages="rankError(item)" />
                      <span class="error-text" v-if="rankError(item)">{{rankError(item)}}</span>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-5">Настройки расчета по диапазонам</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table :headers="headersDiapazon" :items="editedItem.scales2" :items-per-page="-1" hide-default-footer>
                    <template v-slot:item.minimum="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.norm_min" :error-messages="rankError2(item, item.norm_min)" />
                      <span class="error-text" v-if="rankError2(item, item.norm_min)">{{rankError2(item, item.norm_min)}}</span>
                    </template>
                    <template v-slot:item.from="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.from_value" :error-messages="rankError2(item, item.from_value)" />
                      <span class="error-text" v-if="rankError2(item, item.from_value)">{{rankError2(item, item.from_value)}}</span>
                    </template>
                    <template v-slot:item.till="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.to_value" :error-messages="rankError2(item, item.to_value)" />
                      <span class="error-text" v-if="rankError2(item, item.to_value)">{{rankError2(item, item.to_value)}}</span>
                    </template>
                    <template v-slot:item.maximum="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.norm_max" :error-messages="rankError2(item, item.norm_max)" />
                      <span class="error-text" v-if="rankError2(item, item.norm_max)">{{rankError2(item, item.norm_max)}}</span>
                    </template>
                    <template v-slot:item.rank="{ item }">
                      <v-text-field flat outlined dense class="rank-edit" hide-details v-model="item.decreasing_value" :error-messages="rankError2(item, item.decreasing_value)" />
                      <span class="error-text" v-if="rankError2(item, item.decreasing_value)">{{rankError2(item, item.decreasing_value)}}</span>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- <div v-for="scale in editedItem.scales" :key="scale.id" class="scale">
                <div class="scale-header"><h5 class="text-center blue--text">{{scale.name}} ({{scale.id}})</h5></div>
                <div class="d-flex">
                  <v-checkbox v-model="scale.peak" label="Пик" dense hide-details class="mt-0" />
                  <v-checkbox v-model="scale.down" label="Пик вниз" dense hide-details class="ml-1 mt-0" :disabled="!scale.peak" />
                </div>
                <v-checkbox v-model="scale.limit" label="Ограничение" dense hide-details class="mt-0" :disabled="!scale.peak" />
                <v-text-field label="Ранг" outlined dense class="mt-2 mr-1 rank" v-model="scale.rank" :disabled="!scale.peak" :error-messages="rankError(scale)"
                />
            </div> -->
          </div>

          <v-divider class="mt-8 mb-2"></v-divider>

          <h3 class="my-2">Выберите компетенции</h3>
          <div class="row py-3 mb-2">
            <div class="col-12 col-lg-4 py-0" v-for="chunk in 3" :key="chunk">
              <div v-for="c in competsChunk(chunk-1, 3)" :key="c.id" class="chapter-checkbox"
                :class="{ 'chapter-checkbox-active': editedItem.competences.includes(c.id)}"
              >
                <v-checkbox v-model="editedItem.competences" @change="$v.$touch()" :value="c.id"
                  :label="c.name" dense hide-details class="mt-0" />
              </div>
            </div>
            <div class="error--text error-msg">{{competsErrors}} </div>
          </div>

          <v-divider class="my-2"></v-divider>

          <!-- Веса  -->
          <h3 class="my-2">Веса</h3>
          <div class="my-2 scale-wrapper">
            <div class="scale">
              <h5 class="text-center mb-5 blue--text">Личностный опросник</h5>
              <v-text-field outlined dense class="rank ml-2" v-model="editedItem.mmil_weight" :error-messages="weightErrors1"
                  @input="$v.editedItem.mmil_weight.$model = $event, onInput('mmil_weight')"
              />
            </div>
            <div class="scale">
              <h5 class="text-center mb-5 blue--text">Логика</h5>
              <v-text-field outlined dense class="rank ml-2" v-model="editedItem.logic_weight" :error-messages="weightErrors2"/>
            </div>
            <div class="scale">
              <h5 class="text-center mb-5 blue--text">Интересы</h5>
              <v-text-field outlined dense class="rank ml-2" v-model="editedItem.interests_weight" :error-messages="weightErrors3"/>
            </div>
          </div>

          <v-divider class="my-2"></v-divider>
          <h3 class="my-2">Интеллектуальный тест</h3>

          <div class="my-2 scale-wrapper">
            <div v-for="ls in editedItem.logic_scales" :key="ls.id" class="scale">
              <h5 class="text-center mb-5 blue--text">{{ls.name}}</h5>
                <div class="d-flex">
                  <v-text-field label="От" outlined dense class="rank"
                    v-model="ls.min_value" :error-messages="logicError(ls, 'min_value')"
                    @input2="$v.editedItem.logic_verbal.min_value.$model = $event, onInput('name')"
                  />
                  <v-text-field label="До" outlined dense class="rank ml-2"
                    v-model="ls.max_value" :error-messages="logicError(ls, 'max_value')"
                    @input2="$v.editedItem.logic_verbal.max_value.$model = $event, onInput('name')"
                  />
                </div>
            </div>
          </div>

          <v-divider class="my-2"></v-divider>

          <h3 class="my-2">Карта интересов</h3>
            <div class="row mt-4 ml-2" >
              <h5 class="text-center mb-5 blue--text mt-2">Число интересов в расчете:</h5>
              <div class="ml-4" style="width:220px;">
                <v-text-field outlined dense class="rank ml-2" v-model="editedItem.interests_number" :error-messages="interestErrors"
                  @input="$v.editedItem.interests_number.$model = $event, onInput('interests_number')"
                />
              </div>
            </div>

          <div class="_scale-wrapper">
            <v-data-table :headers="headersInterest" :items="editedItem.interests" :items-per-page="-1" hide-default-footer>
              <template v-slot:item.peak="{ item }">
                <v-simple-checkbox :ripple="false" :value="Boolean(item.peak)" @input="item.peak=$event" color="primary" />
              </template>
              <template v-slot:item.rank="{ item }">
                  <v-text-field flat outlined dense class="rank-edit" hide-details
                    v-model="item.rank" :disabled="!item.peak" :error-messages="interestRankError(item)"
                  />
                  <span class="error-text" v-if="interestRankError(item)">{{interestRankError(item)}}</span>
              </template>
            </v-data-table>

              <!-- <div v-for="interest in editedItem.interests" :key="interest.id" class="chapter-checkbox interest">
                <v-checkbox v-model="interest.peak" @change="$v.$touch()" :label="interest.name" dense hide-details class="mt-0" />
                <v-text-field label="Ранг" outlined dense class="mt-5 rank" :disabled="!interest.peak"
                  v-model="interest.rank"  :error-messages="interestRankError(interest)"
                />
              </div> -->
          </div>
          <div class="error--text error-msg">{{interestsErrors}} </div>

          <v-divider class="my-2"></v-divider>

          <h3 class="my-2">Введите стоимость</h3>
          <v-text-field label="Cтоимость *" outlined dense suffix="₽"
              v-model="editedItem.cost" @input="$v.editedItem.cost.$model = $event, onInput('cost')" :error-messages="costErrors"
          />

          <div v-for="(err, ind) in errors.create" :key="ind" class="error--text">
            <template v-if="!editedItem.hasOwnProperty(err[0])">
              <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
            </template>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary" :loading="loading.create"
              :disabled="loading.create || !$store.getters['auth/isAllowed']('change_professiogram')"
            >
              Сохранить
            </v-btn>
          </div>
        </div>
      </form>
    </v-card>
    <CreateChapterModal v-model="showDlgCreateChapter" @created="chapterCreated"/>
    <ConfirmDialog v-model="showDlgCancel" text="Вы уверены что хотите выйти?<br>
        Все несохраненные данные будут потеряны." okText="Выйти" @action="isDialogVisible = false"/>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import api from '@/common/api';
import { mapActions, mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import CreateChapterModal from '@/components/profgrams/CreateChapterModal';
import ConfirmDialog from '@/components/profgrams/ConfirmDialog';
import Spoiler from '@/components/ui/Spoiler';

export default {
  components: {
    CreateChapterModal, ConfirmDialog, Spoiler,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      calcType: 0,
      calcTypes: [{ id: 0, text: "По пикам" }, { id: 1, text: "По диапазонам" }],
      showDlgCreateChapter: false,
      showDlgCancel: false,
      customers: [],

      headersPsycho: [
        { text: 'Наименование', align: 'start', sortable: false, value: 'name' },
        { text: 'Пик', sortable: false, align: 'center', value: 'peak' },
        { text: 'Пик вниз', sortable: false, align: 'center', value: 'down' },
        { text: 'Ограничение', sortable: false, align: 'center', value: 'limit' },
        { text: 'Ранг', sortable: false, align: 'left', value: 'rank' },
      ],
      headersDiapazon: [
        { text: 'Наименование', align: 'start', sortable: false, value: 'name' },
        { text: 'Минимум', sortable: false, align: 'center', value: 'minimum' },
        { text: 'Попадание от', sortable: false, align: 'center', value: 'from' },
        { text: 'Попадание до', sortable: false, align: 'center', value: 'till' },
        { text: 'Максимум', sortable: false, align: 'left', value: 'maximum' },
        { text: 'Уменьшение', sortable: false, align: 'left', value: 'rank' },
      ],
      headersInterest: [
        { text: '', sortable: false, align: 'center', value: 'peak' },
        { text: 'Наименование', align: 'start', sortable: false, value: 'name' },
        { text: 'Ранг', sortable: false, align: 'left', value: 'rank' },
      ],
    };
  },
  validations: {
    editedItem: {
      name: {
        required,
        isUnique(value) {
          if (value === '' || this.editMode) return true;
          if (this.profs) return !this.profs.find((el) => el.name === value);
          return true;
        },
      },
      owner: { required },
      chapters: { required },
      competences: { required },
      interests: { required },
      cost: { required, minValue: minValue(1000) },
      interests_number: { required, minValue: minValue(1) },
      mmil_weight: {
        summ100(value) {
          return +value + (+this.editedItem.logic_weight) + (+this.editedItem.interests_weight) === 100
        }
      },
      logic_weight: {
        summ100(value) {
          return +value + (+this.editedItem.mmil_weight) + (+this.editedItem.interests_weight) === 100
        }
      },
      interests_weight: {
        summ100(value) {
          return +value + (+this.editedItem.mmil_weight) + (+this.editedItem.logic_weight) === 100
        }
      },
    },
  },
  watch: {
    async value(val) {
      if (val) {
        this.$v.$reset();
        await this.loadCustomers();
      }
    },

  },
  computed: {
    ...mapGetters({
      loading: 'profgrams/profs/loading',
      errors: 'profgrams/profs/errors',
      chapters: 'profgrams/chapters/items',
      scales: 'profgrams/profs/scales',
      profs: 'profgrams/profs/profs',
      competences: 'profgrams/compets/items',
      competsChunk: 'profgrams/compets/itemsChunk',
      interests: 'profgrams/profs/interests',
      editedItem: 'profgrams/profs/editedItem',
    }),
    editMode() {
      return !!this.editedItem.id;
    },
    nameErrors() {
      return this.validationErrors('name', {
        required: MESSAGES.REQUIRED,
        isUnique: MESSAGES.PROF_ALREADY_EXISTS,
      });
    },
    chapterErrors() {
      return this.validationErrors('chapters', { required: MESSAGES.PROF_CHAPTERS_EMPTY }).join();
    },
    ownerErrors() {
      const errors = [];
      if (!this.$v.editedItem.owner.$dirty) return errors;
      if (!this.$v.editedItem.owner.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.create.owner ?? errors;
    },
    competsErrors() {
      return this.validationErrors('competences', { required: MESSAGES.PROF_COMPETS_EMPTY }).join();
    },
    interestsErrors() {
      return this.validationErrors('interests', { required: MESSAGES.PROF_INTERESTS_EMPTY }).join();
    },
    costErrors() {
      return this.validationErrors('cost', { required: MESSAGES.REQUIRED, minValue: MESSAGES.PROF_SUMM });
    },
    interestErrors() {
      return this.validationErrors('interests_number', { required: MESSAGES.REQUIRED, minValue: MESSAGES.PROF_INTERESTS_NUMBER });
    },
    weightErrors1() {
      return this.validationErrors('mmil_weight', { summ100: MESSAGES.PROF_WEIGHT });
    },
    weightErrors2() {
      return this.validationErrors('logic_weight', { summ100: MESSAGES.PROF_WEIGHT });
    },
    weightErrors3() {
      return this.validationErrors('interests_weight', { summ100: MESSAGES.PROF_WEIGHT });
    },

    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      clearError: 'profgrams/profs/clearError',
      saveAction: 'profgrams/profs/save',
    }),
    onClose() {
      if (!this.$store.getters['auth/isAllowed']('change_professiogram')) this.isDialogVisible = false;
      else if (this.$v.$anyDirty) this.showDlgCancel = true;
      else this.isDialogVisible = false;
    },
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    chapterCreated(chapter) {
      this.editedItem.chapters.push(chapter.id);
    },
    validationErrors(fieldName, validators) {
      const errors = [];
      for (const err of this.errors.create) {
        if (fieldName===err[0]) errors.push(...err[1]);
      }

      if (!this.$v.editedItem[fieldName].$dirty) return errors;
      for (const key in validators) {
        if (!this.$v.editedItem[fieldName][key]) {
          errors.push(validators[key]);
        }
      }
      return errors;
    },
    rankError(sc) {
      const { rank } = sc;
      if (+rank === 0) return;
      const identical = this.editedItem.scales.filter((el) => el.id !== sc.id).find((el) => +el.rank === +rank);
      if (identical) {
        return 'Ранг должен быть уникальным';
      }
    },
    rankError2(sc, value) {
      if (isNaN(value) || +value<0 || +value>100)
        return 'Введите число от 0 до 100';
    },
    interestRankError (interest) {
      const { rank } = interest;
      if (+rank === 0) return;
      const identical = this.editedItem.interests.filter((el) => el.id !== interest.id).find((el) => +el.rank === +rank);
      if (identical) {
        return 'Ранг должен быть уникальным';
      }
    },
    logicError(ls, field) {
      if (+ls.min_value===0 && +ls.max_value===0) return
      if (+ls.min_value > +ls.max_value)
        return 'Максимальное значение должно быть больше минимального';
      if (+ls[field] > 100 || +ls[field] < 0 )
        return 'Значение должно быть в диапазоне от 0 до 100';
    },
    saveProf() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveAction(this.editedItem).then((resp) => {
          if (resp)
            this.$emit('input', false);
        });
      }
    },
    async loadCustomers() {
      try {
        this.customers = await api.get('/users/customers/');
        this.customers = this.customers.sort((a, b) => {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return aname.localeCompare(bname);
        })
      } catch (err) {
        this.$toast.error('Ошибка при загрузке пользователей', '', { position: 'topRight' });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.scale-wrapper {
  position: relative;
  display: flex;
  // overflow-x:  auto;
  flex-wrap: wrap;
}

.scale {
  padding: 5px 8px 8px 8px;
  border: 1px solid $color-gray;
  border-radius: 5px;
  width: 200px;
  flex: 200px 0 0;
  margin-right: 20px;
  margin-bottom: 10px;
  .rank {
    // width: 90%;
  }
  .scale-header {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.error-msg {
  height: 20px;
  font-size: 12px;
  margin-left: 10px;
}
.chapter-checkbox {
  border: 1px solid $color-gray;
  border-radius: 4px;
  padding: 5px 12px 7px 10px;
  margin: 0px 10px 10px 0;
}
.chapter-checkbox-active {
  border: 1px solid $color-blue;
}

.error-text {
   color: red;
   font-size: 8px;
}
.rank-edit {
  width: 80px;
}


::v-deep .interest {
  padding: 5px 16px 16px 16px;
  border: 1px solid $color-gray;
  border-radius: 5px;
  width: 330px;
  flex: 330px 0 0;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .rank {
    // align-self: flex-end;
    flex-shrink: 0;
    flex: 70px 0 0;
    margin-top: 10px;
    width: 100px;
  }
  .v-text-field__details {
    max-width: 150% !important;
    padding: 0 !important;
    margin: 0 -20px !important;
  }
}

@media screen and (max-width: 1264px) {
    .in-3-cols, .in-4-cols {
        columns: 2 auto;
    }
}
@media screen and (max-width: 960px) {
    .in-3-cols, .in-4-cols {
        columns: 1 auto;
        display: inline-block;
    }
}

</style>
