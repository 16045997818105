<template>
  <v-dialog v-model="isDialogVisible" max-width="1100px">
    <v-card class="pa-sm-8">
      <v-btn class="button-close ma-4" icon @click="isDialogVisible = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <form @submit.prevent="onSave">
        <div class="row">
          <div class="col-6">
            <h2>{{editedItem.id ? 'Редактирование раздела' : 'Создание раздела'}}</h2>
          </div>
          <div>
            <v-switch v-model="editedItem.is_active" dense class="profile-switch ml-auto mb-4" hide-details color="success">
              <div slot="label" :class="editedItem.is_active ? 'success--text' : 'error--text'">Активен</div>
            </v-switch>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <v-text-field label="Название раздела *" :value="editedItem.name" @input="$v.editedItem.name.$model=$event, onInput('name')"
              :error-messages="nameErrors" outlined dense/>
          </div>
          <div class="col-6">
            <v-select  v-model="editedItem.owner" :items="customers" label="Владелец"
              :item-text="(item) => (item.first_name || item.last_name ? item.last_name + ' ' + item.first_name : 'имя не задано') +
                                ' (' + (item.user_role === 'corpclient' ? 'Клиент' : (item.user_role === 'partner' ? 'Партнер' : '')) + ', ' + item.email + ')'"
              item-value="id" outlined dense
              :disabled="!$store.getters['auth/isAllowed']('change_chapter')"
              :error-messages="ownerErrors"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <v-text-field label="Описание" v-model="editedItem.description" outlined dense hide-details/>
          </div>
        </div>

        <div class="row" _v-if="$store.getters['auth/isAllowed']('change_clients_of_chapter')">
          <div class="col-6">
            <div class="users-list-title">
              <div>Представители:</div>
              <v-btn color="_primary" fab text small @click="editUsers(editedItem.representatives)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
            </div>
            <div class="users-list">
              <div v-for="id in sortedList(editedItem.representatives)" :key="id">{{userName(id)}}</div>
            </div>
            <div class="users-list-error"><div v-for="(err,i) in representativesErrors" :key="i">{{err}}</div></div>
          </div>
          <div class="col-6">
            <div class="users-list-title">
              <div>Пользователи:</div>
              <v-btn color="_primary" fab text small @click="editUsers(editedItem.clients)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
            </div>
            <div class="users-list">
              <div v-for="id in sortedList(editedItem.clients)" :key="id">{{userName(id)}}</div>
            </div>
            <div class="users-list-error"><div v-for="(err,i) in clientsErrors" :key="i">{{err}}</div></div>
          </div>
        </div>

        <div v-for="(err, ind) in errors.create" :key="ind" class="error--text">
          <template v-if="!editedItem.hasOwnProperty(err[0])">
            <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
          </template>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary" :loading="loading.create"
              :disabled="!$store.getters['auth/isAllowed']('change_chapter') || loading.create || !editedItem.name"
            >
              Сохранить
            </v-btn>
            <!-- {{$store.getters['auth/isAllowed']('change_clients_of_chapter')}}
            {{$v.editedItem.representatives}} -->
          </div>
        </div>
      </form>
    </v-card>
    <AddUsersToChapterDialog v-model="showEditListDlg" :customers="customers" :list="editedList" :title="listDlgTitle" @save="saveList"/>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import api from '@/common/api';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import AddUsersToChapterDialog from '@/components/profgrams/AddUsersToChapterDialog';

export default {
  name: 'ChapterCreateModal',
  components: {
    AddUsersToChapterDialog
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    chapter: Object,
  },
  data() {
    return {
      editedItem: {
        name: '',
        description: '',
        is_active: false,
        owner: 0,
        representatives: [],
        clients: []
      },
      defaultItem: {
        name: 'Новый раздел',
        description: '',
        is_active: true,
        owner: 0,
        representatives: [],
        clients: []
      },
      customers: [],
      showEditListDlg: false,
      editedList: [],
      listDlgTitle: '',
    };
  },
  validations: {
    editedItem: {
      name: {
        required,
        isUnique(value) {
          if (value === '') return true;
          if (this.chapters && !this.editedItem.id) return !this.chapters.find((el) => el.name === value);
          return true;
        },
      },
      owner: { required },
      clients: { required2(value) {
        return !this.$store.getters['auth/isAllowed']('change_clients_of_chapter') || value.length;
        }
      },
      representatives: { required2(value) {
          return !this.$store.getters['auth/isAllowed']('change_clients_of_chapter') || value.length;
        }
      },
    }
  },
  watch: {
    async value(val) {
      if (val) {
        this.$v.$reset();
        await this.loadCustomers();
        if (this.chapter) {
          this.editedItem = cloneDeep(this.chapter);
        } else {
          this.editedItem = cloneDeep(this.defaultItem);
        }
      } else {
        this.editedItem = cloneDeep(this.defaultItem);
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'profgrams/chapters/loading',
      errors: 'profgrams/chapters/errors',
      chapters: 'profgrams/chapters/items',
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      if (!this.$v.editedItem.name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.name.isUnique) errors.push(MESSAGES.CHAPTER_ALREADY_EXISTS);
      return this.errors.create.name ?? errors;
    },
    ownerErrors() {
      const errors = [];
      if (!this.$v.editedItem.owner.$dirty) return errors;
      if (!this.$v.editedItem.owner.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.create.owner ?? errors;
    },
    clientsErrors() {
      if (!this.$store.getters['auth/isAllowed']('change_clients_of_chapter')) {
        return true;
      }
      const errors = [];
      if (!this.$v.editedItem.clients.$dirty) return errors;
      if (!this.$v.editedItem.clients.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.create.find((err) => err[0] === 'clients')?.[1] ?? errors;
    },
    representativesErrors() {
      if (!this.$store.getters['auth/isAllowed']('change_clients_of_chapter')) {
        return true;
      }
      const errors = [];
      if (!this.$v.editedItem.representatives.$dirty) return errors;
      if (!this.$v.editedItem.representatives.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.create.find((err) => err[0] === 'representatives')?.[1] ?? errors;
      // return this.errors.create.representatives ?? errors;
    },
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      clearError: 'profgrams/chapters/clearError',
      saveChapter: 'profgrams/chapters/saveChapter',
      loadChapters: 'profgrams/chapters/load',
    }),
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    async loadCustomers() {
      try {
        this.customers = await api.get('/users/customers/');
        this.customers = this.customers.sort((a, b) => {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return aname.localeCompare(bname);
        })
      } catch (err) {
        this.$toast.error('Ошибка при загрузке пользователей', '', { position: 'topRight' });
      }
    },
    userName(id) {
      const customer = this.customers.find(el => el.id === id);
      if (!customer) return 'Пользователь не найден';
      return customer.first_name || customer.last_name ? `${customer.last_name} ${customer.first_name}` : 'имя не задано';
    },
    sortedList(list) {
      return list.slice().sort((idA, idB) => {
        const a = this.customers.find(el => el.id === idA);
        const b = this.customers.find(el => el.id === idB);
        const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
        const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
        return aname.localeCompare(bname);
      });
    },
    editUsers(items) {
      this.listDlgTitle = items === this.editedItem.clients ? 'Выберите пользователей' : 'Выберите представителей';
      this.editedList = items;
      this.showEditListDlg = true;
    },
    saveList(list) {
      if (this.editedList === this.editedItem.clients) {
        this.editedItem.clients = list;
      } else {
        this.editedItem.representatives = list;
      }
      this.$v.$reset();
    },
    async onSave() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const chapter = await this.saveChapter(this.editedItem);
      if (chapter) {
        this.$emit('input', false);
        this.$emit('created', chapter);
        this.loadChapters();
      }
    },

  },
};
</script>

<style scoped>
.users-list {
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.users-list div{
  padding: 2px 5px;
  white-space: nowrap;
}
.users-list-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.users-list-error {
  margin: 2px 0 8px 12px;
  min-height: 14px;
  font-size: 12px;
  color: #ff6e6d !important;
}
</style>
