<template>
  <v-dialog v-model="isDialogVisible" max-width="950px">
    <v-card class="pa-sm-8">
      <v-btn class="button-close ma-4" icon @click="isDialogVisible = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <form @submit.prevent="save">
        <h2>{{title}}</h2>

        <div class="row mt-2">
          <div class="col-12">
            <v-data-table v-model="editedList" :headers="headers" :items="customers" show-select dense
              class="elevation-1" hide-default-footer :items-per-page="-1" height="400px" :custom-sort="customSort">
              <template v-slot:item.name="{ item }">
                {{item.first_name || item.last_name ? `${item.last_name} ${item.first_name}` : 'имя не задано'}}
              </template>
              <template v-slot:item.user_role="{ item }">
                {{item.user_role === 'corpclient' ? 'Клиент' : (item.user_role === 'partner' ? 'Партнер' : '-')}}
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary">
              Сохранить
            </v-btn>
          </div>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { cloneDeep } from 'lodash';

export default {
  name: 'CandidateCreateModal',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    list: Array,
    customers: Array,
    title: String,
  },
  data() {
    return {
      editedList: [],
      headers: [
        { text: 'Тип', value: 'user_role' },
        { text: 'Фамилия Имя', value: 'name' },
        { text: 'Email', value: 'email' },
      ]
    };
  },
  watch: {
    async value(val) {
      if (val) {
        this.editedList = this.customers.filter(el => this.list.includes(el.id));
      }
    },
  },
  computed: {
    ...mapGetters({
    }),
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ...mapActions({
    }),
    userName(id) {
      const customer = this.customers.find(el => el.id === id);
      if (!customer) return 'Пользователь не найден';
      return customer.first_name || customer.last_name ? `${customer.first_name} ${customer.last_name}` : 'имя не задано';
    },
    save() {
      this.$emit('input', false);
      this.$emit('save', this.editedList.map(el => el.id));
    },
    customSort(items, [index], [isDesc]) {
      console.log(index);
      items.sort((a, b) => {
        let aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
        let bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
        if (index === 'email') {
          aname = a.email;
          bname = b.email;
        }
        if (index === 'user_role') {
          aname = a.user_role === 'corpclient' ? 'Клиент' : 'Партнер';
          bname = b.user_role === 'corpclient' ? 'Клиент' : 'Партнер';
        }
        return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
      });
      return items;
    }
  },
};
</script>

<style scoped>
</style>
