<template>
  <div class="page">
    <div class="row">
      <div class="col-12 d-flex align-center flex-wrap">
        <!-- <v-slide-x-transition>
        <v-btn v-if="modeEdit" class="mr-5" text @click="modeEdit=false">
          <v-icon>mdi-arrow-left</v-icon> Назад
        </v-btn>
        </v-slide-x-transition> -->
        <h1 class="font-lg">Профессиограммы</h1>
        <v-btn v-if="!modeEdit && ($store.getters['auth/isAllowed']('delete_chapter') ||
            $store.getters['auth/isAllowed']('delete_professiogram'))"
          class="ml-auto" fab small @click="startEditMode()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="modeEdit" class="ml-auto" outlined @click="modeEdit = false">Отменить</v-btn>
        <v-btn v-if="modeEdit" class="ml-5" @click="showDlgDelete = true" color="error"
          :disabled="!selectedChapters.length && !selectedProfs.length"
        >
          Удалить
        </v-btn>
      </div>
    </div>

    <div class="row d-flex mb-8">
      <div class="col-12">
        <v-btn class="font-xs px-5 py-5 mb-2 mr-3" color="primary" outlined
          @click="createProf(0)"
          :disabled="modeEdit"
          v-if="$store.getters['auth/isAllowed']('add_professiogram')"
        >
          Создать профессиограмму
        </v-btn>
        <v-btn class="font-xs px-5 py-5 mb-2" color="primary" outlined
          @click="onCreateChapter()"
          :disabled="modeEdit"
          v-if="$store.getters['auth/isAllowed']('add_chapter')"
        >
          Создать раздел
        </v-btn>
      </div>
    </div>

    <CreateChapterModal v-model="showDlgCreateChapter" :chapter="curChapter" />
    <CreateProfModal v-model="showDlgCreateProf" />
    <v-dialog v-model="showDlgDelete" max-width="400px">
      <v-card class="pa-8">
        <div class="mt-6">
          Вы уверены, что хотите удалить?<br>Действие не может быть отменено.
        </div>
        <div class="mt-6 d-flex justify-space-around">
          <v-btn @click="showDlgDelete = false" class="px-6" outlined text>Отмена</v-btn>
          <v-btn @click="deleteItems()" class="px-6 _mr-12" color="error" :loading="loading.delete || loadingChapters.delete">
            Удалить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <div class="row">
      <div class="col-12">
        <v-text-field label="Введите название раздела" prepend-inner-icon="mdi-magnify"
          class="font-sm mb-6" dense hide-details outlined :value="filterChapter.name"
          @input="debouncedSetFilterChapterName"
        />
        <h5 class="mb-2">Ваши профессиограммы: </h5>
        <v-expansion-panels v-if="chapters.length" _multiple v-model="opened">
          <v-expansion-panel v-for="c in visibleChapters" :key="c.id">
            <v-expansion-panel-header class="grey lighten-5">
              <div class="d-flex align-items-center justify-space-between">
                <div class="d-flex align-items-center flex-wrap">
                  <v-checkbox
                    v-if="modeEdit && $store.getters['auth/isAllowed']('delete_chapter')"
                    v-model="selectedChapters" :value="c.id"
                    dense hide-details class="mt-0"
                    @click.stop="selectChapter(c.id)"
                  />
                  <span class="chapter-title" :class="{'not-active': !c.is_active}">{{c.name}}</span>
                </div>
                <v-btn fab class="mr-4" small text _outlined @click.stop="onEditChapter(c)" v-if="$store.getters['auth/isAllowed']('view_chapter')">
                  <v-icon >mdi-pencil-outline</v-icon>
                </v-btn>

              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="chapter-profs">
                <div v-for="p in chapterProfs(c.id)" :key="p.id" class="prof-in-chapter">
                  <v-checkbox
                    v-if="modeEdit && $store.getters['auth/isAllowed']('delete_professiogram')"
                    v-model="selectedProfs" :value="p.id"
                    dense hide-details class="mt-0 pt-0"
                  />
                  <div @click.prevent="editProf(p)" class="prof">
                    {{p.name}}
                  </div>
                  <!-- <span class="body-2 px-2 text-gray" v-if="$store.getters['auth/isAllowed']('view_professiogram')">
                     &bull; {{p.cost | formatPrice}}
                  </span> -->
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="" >
          <div v-if="filterChapter.name.length">Не найдено разделов, название которых содержит строку "{{filterChapter.name}}"</div>
          <div v-else>Список разделов пуст</div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CreateChapterModal from '@/components/profgrams/CreateChapterModal';
import CreateProfModal from '@/components/profgrams/CreateProfModal';
import { debounce } from 'lodash';

export default {
  name: 'Profgrams',
  metaInfo: {
    title: 'Профессиограммы',
  },
  components: {
    CreateChapterModal, CreateProfModal,
  },
  data: () => ({
    showDlgCreateChapter: false,
    showDlgCreateProf: false,
    showDlgDelete: false,
    dialogDeleteShow: false,
    opened: [],
    modeEdit: false,
    selectedChapters: [],
    selectedProfs: [],
    curChapter: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'profgrams/profs/loading',
      loadingChapters: 'profgrams/chapters/loading',
      chapters: 'profgrams/chapters/filteredItems',
      filterChapter: 'profgrams/chapters/filter',
      profs: 'profgrams/profs/profs',
      selected: 'profgrams/chapters/selected',
      chapterProfs: 'profgrams/profs/chapterProfs',
      user: 'auth/user',
    }),
    visibleChapters() {
      return this.chapters.filter(el => this.$store.getters['profgrams/chapters/isChapterVisible'](el, this.user))
    }
  },
  methods: {
    ...mapActions({
      loadCompets: 'profgrams/compets/load',
      loadChapters: 'profgrams/chapters/load',
      loadProfs: 'profgrams/profs/load',
      deleteProfgrams: 'profgrams/profs/delete',
      deleteChapters: 'profgrams/chapters/delete',
      setFilterName: 'profgrams/chapters/setFilterName',
    }),
    ...mapMutations({
      setEditedItem: 'profgrams/profs/setEditedItem',
      createEditedItem: 'profgrams/profs/createEditedItem',
    }),
    // eslint-disable-next-line func-names
    debouncedSetFilterChapterName: debounce(function (val) {
      this.setFilterName(val);
    }, 500),
    selectChapter(id) {
      const selected = this.selectedChapters.includes(id);
      this.chapterProfs(id).forEach((el) => {
        const ind = this.selectedProfs.indexOf(el.id);
        if (selected && ind === -1) this.selectedProfs.push(el.id);
        if (!selected && ind !== -1) this.selectedProfs.splice(ind, 1);
      });
    },
    startEditMode() {
      this.selectedChapters = [];
      this.selectedProfs = [];
      this.modeEdit = true;
    },
    onCreateChapter() {
      this.curChapter = null;
      this.showDlgCreateChapter = true;
    },
    onEditChapter(chapter) {
      this.curChapter = chapter;
      this.showDlgCreateChapter = true;
    },
    async deleteItems() {
      await this.deleteProfgrams(this.selectedProfs);
      await this.deleteChapters(this.selectedChapters);
      this.showDlgDelete = false;
      this.modeEdit = false;
    },
    createProf(chapterId) {
      this.createEditedItem({ 
        chapters: chapterId ? [{id: chapterId }] : [],
        interests: [],
        competences: [],
        cost: 0,
        mmil_weight: 0,
        interests_weight: 0,
        logic_weight: 0,
        interests_number: 0,
        owner: 0,
      });
      this.showDlgCreateProf = true;
      if (chapterId && !this.opened.includes(chapterId)) this.opened.push(chapterId);
    },
    editProf(chapter) {
      if (!this.$store.getters['auth/isAllowed']('view_professiogram')) return;
      if (this.modeEdit) return;
      this.setEditedItem(chapter);
      this.showDlgCreateProf = true;
    },
  },
  mounted() {
    this.loadChapters().then(() => {
      this.opened = this.chapters.map((_, i) => i);
    });
    this.loadProfs();
    this.loadCompets();
  },
};
</script>

<style scoped lang="scss">
// .chapter-panel {
//   background-color: #F5F8FF;
//   border-radius: 10px;
// }

.chapter-title {
  line-height: 2rem;
}

.chapter-title.not-active {
  color: #aaa;
}

.prof-in-chapter {
  display: flex;
  align-items: center;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #ccc solid;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .prof {
    flex-grow: 1;
    cursor: pointer;
  }
}

</style>
